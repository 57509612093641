<template>
<div class="row child-component">
    <b-card class="col-lg-8 mx-auto">
        <div class="row">
            <div class="form-group" style="margin-left: 14px">
                <div class="text-left" style="margin-top: 11px">
                    <b-form-checkbox class="prodbilling" id="checkbox-1" v-model="prodbilling" name="checkbox-1" value="true" :disabled="showprodbilling" v-b-tooltip.hover.v-dark.right.html="this.ClientCommissionInfo.length>0 ? tipMethod : ''">
                    </b-form-checkbox>
                </div>
            </div>
            <label class="productionbillinglbl">
                This client is production billing only
            </label>

            <div class="col-lg-8 text-right" style="margin-left:2rem;">

                <button class="btn custom-button btn-link" style="font-family:'Open Sans',sans-serif!important;color:#00a1d2 !important;font-weight:bold;" @click="DeleteAll()" v-if="this.ClientCommissionInfo.length>0">
                    Clear Table
                </button>
                <button @click="AddMedia()" :disabled="TimeOnlySelected || this.prodbilling" :title="
                TimeOnlySelected
                  ? 'Cannot add media as TimeOnly is selected'
                  : ''
              " class="text-uppe.rcase btn custom-button btn-primary">
                    Add Media
                </button>
            </div>
            <!--
            <div class="form-group offset-md-4" :style="{'padding-left':this.ClientCommissionInfo.length>0?'20px':'0px'}">
                <div class="text-right">
                    <button class="btn custom-button btn-link" style="font-family:'Open Sans',sans-serif!important;color:#00a1d2 !important;font-weight:bold;" @click="DeleteAll()" v-if="this.ClientCommissionInfo.length>0">
                        Clear Table
                    </button>
                </div>
            </div>

            <div class="form-group" :class='{"offset-lg-2":!this.ClientCommissionInfo.length>0}' :style="{'padding-left':this.ClientCommissionInfo.length>0?'65px':'50px'}" style="padding-left:50px;">
                <div class="text-right">
                    <button @click="AddMedia()" :disabled="TimeOnlySelected || this.prodbilling" :title="
                TimeOnlySelected
                  ? 'Cannot add media as TimeOnly is selected'
                  : ''
              " class="text-uppercase btn custom-button btn-primary">
                        Add Media
                    </button>
                </div>
            </div> -->
        </div>
        <b-table class="table-editable" striped :fields="fields" :items="ClientCommissionInfo" show-empty ref="table">
            <template v-slot:table-colgroup="scope">
                <col v-for="field in scope.fields" :key="field.key" :style="{
              width:
                field.key == 'DefaultPaymentTerms'
                  ? '25%'
                  : field.key == 'FeeOnly'
                  ? '12%'
                  : field.key == 'CommissionBasis'
                  ? '15%'
                  : '22%',
            }" />
            </template>

            <template v-slot:empty="scope">
                <label class="empty-table-text">Add media to populate the table</label>
            </template>
            <template v-slot:cell(MediaCategory)="row">
                <div class="text-left">
                    <a class="sm" v-if="row.item.Media.length > 0">
                        <b-img v-show="row.detailsShowing" class="collapse-grid" @click="row.toggleDetails" :src="collapseIcon" />
                        <b-img v-show="!row.detailsShowing" class="expand-grid" :src="expandIcon" @click="row.toggleDetails" />
                    </a>
                    <a v-else>
                        <b-img class="delete-icon" :src="deleteIcon" @click="DeleteMedia(row.index)" />
                    </a>
                    <span class="custom-text">{{ row.item.MediaCategory }}</span>
                </div>
                <!-- </div> -->
            </template>
            <template v-slot:row-details="row">
                <table style="width: 100%">
                    <tr v-for="(value, key) in row.item.Media" :key="key">
                        <td style="width: 23%">
                            <b-img class="delete-icon" :src="deleteIcon" @click="DeleteMedia(row.index, key, value.MediaType)" />

                            <label class="custom-inline-text">{{ row.item.MediaCategory }} - {{ value.MediaType }}</label>
                        </td>
                        <td style="width: 12%">
                            <div v-show="value.MediaType != 'Time Only'">
                                <OmniSelect placeholder="Select Fee Only" :showSearch="false" name="Single select" :showLabel="false" :value="value.FeeOnly" v-model="value.FeeOnly" :options="FeeOnlyOptions"></OmniSelect>
                            </div>
                        </td>
                        <td style="width: 20%">
                            <div>
                                <b-form-input v-numericWithDecimal autocomplete="off" placeholder="Enter Commission %" v-show="value.MediaType != 'Time Only'" v-if="value.FeeOnly != 'Yes'" v-model="value.CommissionPercentage"></b-form-input>
                                <label v-else> __ </label>
                            </div>
                        </td>
                        <td style="width: 19%">
                            <div v-show="value.MediaType != 'Time Only'">
                                <OmniSelect placeholder="Select Commission Basis" name="Single select" :showLabel="false" v-if="value.FeeOnly != 'Yes'" v-model="value.CommissionBasis" :options="CommissionBasisList" :showSearch="false"></OmniSelect>

                                <label v-else> __ </label>
                            </div>
                        </td>
                        <td style="width: 29%">
                            <div>
                                <OmniSelect placeholder="Select Default Payment Terms" name="Single select" :showLabel="false" v-if="value.FeeOnly" v-model="value.DefaultPaymentTerms" :options="PaymentTerms" :showSearch="true"></OmniSelect>

                                <label v-else> __ </label>
                            </div>
                        </td>
                    </tr>
                </table>
            </template>

            <template v-slot:cell(FeeOnly)=""> </template>
            <template v-slot:cell(CommissionPercentage)=""> </template>
            <template v-slot:cell(CommissionBasis)=""> </template>
            <template v-slot:cell(DefaultPaymentTerms)=""> </template>
        </b-table>
        <br />
          <div class="col-12 fieldborder" v-if="ShowScreenshot">
              <slot name="label" class="lbltxt">
                <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                    Were the Payment terms above N030 approved by the Network CFO?
                </span>
              </slot>
              <b-form-group label="" class="lbltxt omniipclass" style="margin-left: -2px">
                <b-form-radio-group id="isNetworkCfoApproved" name="isNetworkCfoApproved" v-model="ClientBasicDetails.IsNetworkCfoApproved">
                  <b-form-radio value="true">Yes</b-form-radio>
                  <b-form-radio value="false">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="fieldborder" style="" v-if="ClientBasicDetails.IsNetworkCfoApproved == 'true'">
            <br />
            <label class="lbltxt">Credit Voyager Approval for Payment terms</label>
              <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="screenshotVueDropzone"
                id="screenshotVueDropzone" :useCustomSlot="true" class="customCommissionInfoDropzone" :include-styling="false"
                :options="dropzoneOptions" v-on:vdropzone-file-added="ScreenshotUploadedToDropzone"
                v-on:vdropzone-removed-file="ScreenshotRemovedFromDropzone"
              >
                <div class="dropzone-custom-content">
                  <p class="Drag-and-drop-file-h">
                    <b-img
                      :src="closeFilterIcon"
                      style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      "
                    />
                    Drag and drop file here or
                    <span class="draganddropspan"> browse </span>
                  </p>
                </div>
              </vue-dropzone>
            </div>
        <b-modal no-enforce-focus v-model="showAddMedia" scrollable title="Add Media" :no-close-on-backdrop="true">
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Add Media</h5>
                <button class="close" @click="CancelAdd()"></button>
            </template>
            <div>
                <!-- <div class="row ml-5 mr-4 mt-3">
            <b-form-group class="timeonly-text">
              <slot name="label">
                <legend class="col-form-label pt-0">
                Is this request Time Only ?

                <svg
                  style="margin-bottom: 1px; margin-left: 5px"
                  width="9px"
                  height="9px"
                  viewBox="0 0 9 9"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  v-b-tooltip.hover.v-dark.right.html="
                    'If this is checked off, the different Media Types will be greyed out as no Media will be placed on behalf of the new Client being requested.'
                  "
                >
                  <g
                    id="LATEST"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                      id="info"
                      fill="#00A1D2"
                      fill-rule="nonzero"
                    ></path>
                  </g></svg>
              </legend></slot>
              <b-form-radio-group
                :disabled="ClientCommissionInfo.length > 0"
                id="time-only"
                v-model="TimeOnly"
                name="time-only"
              >
                <b-form-radio value="true">Yes</b-form-radio>
                <b-form-radio value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div> -->
                <b-card class="secondary-card commission-basis-with-fee ml-4 mr-4" v-show="TimeOnly != 'true'">
                    <b-row class="mt-2 ml-1 mr-1">
                        <div style="margin-left:29px !important;">
                            <slot name="label" class="lbltxt">
                                <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                                    Media
                                </span>
                                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-b-tooltip.hover.v-dark.right.html="
                  'For Network selection, client opened against Network, Cable and Syndication'
                ">
                                    <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z" id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </slot>
                        </div>
                        <b-form-group class="col-md-12">
                            <div class="text-center action-cell">
                                <OmniSelect placeholder="Select Media" name="Media" :showLabel="false" label="Select Media" :options="MediaList" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" v-model="MediaTypes" />
                            </div>
                        </b-form-group>
                    </b-row>
                    <label style="margin-left: 20px">Please select a billing type:</label>
                    <b-row class="ml-3">
                        <b-form-group>
                            <b-form-radio class="inline-custom-radio-commission" v-model="FeeOnly" name="CommissionBasis-modal" value="Yes">Fee</b-form-radio>
                            <br />
                            <b-form-radio class="inline-custom-radio-commission" v-model="CommissionBasis" name="CommissionBasis-modal" value="Net">Net</b-form-radio>
                            <br />
                            <b-form-radio class="inline-custom-radio-commission" v-model="CommissionBasis" name="CommissionBasis-modal" value="Gross">Gross</b-form-radio>
                        </b-form-group>
                    </b-row>
                    <b-row class="mt-2 ml-1 mr-1" v-if="CommissionBasis != Gross || Net">
                        <b-col align-self="center" cols="12">
                            <b-form-group id="commission-modal-commission-percentage" label="Commission %" label-for="commision-percentage">
                                <b-form-input autocomplete="off" id="commision-percentage" v-numericWithDecimal v-model="CommissionPercentage" placeholder="Enter Commission %" :class="{
                      'errorborder': !$v.CommissionPercentage.decimal && $v.CommissionPercentage.$dirty,
                    }" @blur="$v.CommissionPercentage.$touch()" @focus="ResetDirty('CommissionPercentage')" max="100"></b-form-input>
                                <p v-show="!$v.CommissionPercentage.decimal && $v.CommissionPercentage.$dirty" class="error-message-format">
                                    Please enter valid decimal number. If .(dot) is entered add
                                    at least one number after that.
                                </p>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="text-right">
                    <button class="btn btn-link text-uppercase" @click="CancelAdd()">
                        Cancel
                    </button>
                    <button :disabled="$v.$invalid" class="text-uppercase btn custom-button btn-primary" @click="AddSelectedMedia()">
                        Add
                    </button>
                </div>
            </template>
        </b-modal>
    </b-card>
    <div class="form-group text-right footer-buttons">
        <button class="btn custom-button btn-link" @click="Cancel()">
            Cancel
        </button>
        <button
        class="text-uppercase btn btn-secondary btn-primary"
        @click="SaveasDraft()"
      >
        SAVE FOR LATER
      </button>
        <button class="btn custom-button btn-primary" @click="Previous()">
            BACK
        </button>
        <button class="btn custom-button btn-primary" :disabled="!this.prodbilling && ($v.$invalid || showAddMedia)" @click="Next()">
            Next
        </button>

    </div>
    <b-modal v-model="ShowModelPopup" hide-footer :no-close-on-backdrop="true">
        <div>
            <p class="modelmessage">
                You must retrieve the approval from Credit Voyager prior to submitting this client request.
            </p>
        </div>
        <template v-slot:modal-header="{ close }">
            <h5 class="modal-title">Credit Voyager Approval Required</h5>
            <button class="close" @click="closeModel()"></button>
        </template>

        <div class="text-right">
            <button class="btn btn-link text-uppercase" @click="closeModel()">
                Ok
            </button>
        </div>
    </b-modal>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import store from "@/store";
import {
    requiredIf,
    minLength
} from "vuelidate/lib/validators";
import service from "../../services/Dropdowns";
import {
    NETMedia,
    PRINTMedia,
    SPOTMedia
} from "../../utilities/MediaList";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
    validations: {
        prodbilling: {
            required: requiredIf(function () {
                return !this.showAddMedia;
            }),
        },

        ClientCommissionInfo: {
            required: requiredIf(function () {
                return !this.showAddMedia;
            }),
            minLength: minLength(1),
            $each: {
                Media: {
                    $each: {
                        CommissionPercentage: {
                            required: requiredIf(function (model) {
                                return !this.showAddMedia && model.FeeOnly != "Yes";
                            }),
                            decimal(commission, model) {
                                return !this.showAddMedia && model.FeeOnly != "Yes" ?
                                    /^([0-9]*|[0-9]*.[0-9][0-9]*)$/.test(commission) :
                                    true;
                            },
                        },
                        CommissionBasis: {
                            required: requiredIf(function (model) {
                                return !this.showAddMedia && model.FeeOnly != "Yes";
                            }),
                        },
                        DefaultPaymentTerms: {
                            required: requiredIf(function (model) {
                                return !this.showAddMedia && model.FeeOnly != "Yes";
                            }),
                        },
                    },
                },
            },
        },
        MediaTypes : {
            required: requiredIf(function () {
                return this.showAddMedia;
            }),
        },
        FeeOnly: {
            required: requiredIf(function () {
                return (
                    this.showAddMedia &&
                    this.TimeOnly != "true" &&
                    this.CommissionBasis == null
                );
            }),
        },
        CommissionBasis: {
            required: requiredIf(function () {
                return (
                    this.showAddMedia && this.TimeOnly != "true" && this.FeeOnly != "Yes"
                );
            }),
        },
        CommissionPercentage: {
            decimal(commission) {
                return this.showAddMedia &&
                    this.TimeOnly != "true" &&
                    this.FeeOnly != "Yes" &&
                    (this.CommissionBasis == "Net" || this.CommissionBasis == "Gross") ?
                    /^([0-9]*|[0-9]*.[0-9][0-9]*)$/.test(commission) :
                    true;
            },
            required: requiredIf(function () {
                return (
                    this.showAddMedia &&
                    this.TimeOnly != "true" &&
                    this.FeeOnly != "Yes" &&
                    (this.CommissionBasis == "Net" || this.CommissionBasis == "Gross")
                );
            }),
        },
        ClientBasicDetails : {
            CreditVoyagerApprovalFiles: {
               minLength: minLength(1),
                required: requiredIf(function () {
                    return !this.showAddMedia && this.ShowScreenshot;
                })
            }
        },
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    // beforeUpdate() {
    //   this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    // },
    components: {
        vueDropzone: vue2Dropzone,
    },
    mounted() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
        var thElm;
        var startOffset;
        if (this.id && parseInt(this.id) > 0) {
            this.prodbilling = this.ClientBasicDetails.ProductionBilling;
        }
        Array.prototype.forEach.call(
            document.querySelectorAll("table th"),
            function (th) {
                th.style.position = "relative";

                var grip = document.createElement("div");
                grip.innerHTML = "&nbsp;";
                grip.style.top = 0;
                grip.style.right = 0;
                grip.style.bottom = 0;
                grip.style.width = "5px";
                grip.style.position = "absolute";
                grip.style.cursor = "col-resize";
                grip.addEventListener("mousedown", function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                });

                th.appendChild(grip);
            }
        );

        document.addEventListener("mousemove", function (e) {
            if (thElm) {
                thElm.style.width = startOffset + e.pageX + "px";
            }
        });

        document.addEventListener("mouseup", function () {
            thElm = undefined;
        });
        if (this.IsEdit == true) {
            this.ShowScreenshot = this.DidRequiresNetworkCfoApproval();
            if (this.ClientBasicDetails.IsEditDataLoaded == true) {
                this.ManuallyUploadFiles(this.ClientBasicDetails.CreditVoyagerApprovalFiles);
            }
        }
    },
    data() {
        return {
            nonactiveclass: "'padding-left': '193px'",
            activeClass: "'padding-left': '65px'",
            tipData: {
                title: 'test'
            },
            productBillingAddress: {},
            productMediaDetails: [],
            commissionInfo: [],
            ProductInfo: [],
            BillingInfo: {},
            clientForm: {},
            showprodbilling: false,
            prodbilling: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/rightarrow.svg"),
            TimeOnly: null,
            showAddMedia: false,
            ShowScreenshot: false,
            ShowModelPopup: false,
            FilesUploaded: false,
            FeeOnlyOptions: [{
                    label: "Yes",
                    value: "Yes"
                },
                {
                    label: "No",
                    value: "No"
                },
            ],
            fields: [
                // { key: "Actions", label: ""},
                {
                    key: "MediaCategory",
                    label: "Media",
                    sortable: true
                },
                {
                    key: "FeeOnly",
                    label: "Fee Only",
                    sortable: true
                },
                {
                    key: "CommissionPercentage",
                    label: "Commission %",
                    sortable: true
                },
                {
                    key: "CommissionBasis",
                    label: "Commission Basis",
                    sortable: true
                },
                {
                    key: "DefaultPaymentTerms",
                    label: "Default Payment Terms",
                    sortable: true,
                },
            ],
            FeeOnly: null,
            CommissionBasisOptions: [{
                    text: "NET",
                    value: "Net"
                },
                {
                    text: "GROSS",
                    value: "Gross"
                },
            ],
            NETList: [],
            SPOTList: [],
            PRINTList: [],
            SelectedNETList: [],
            SelectedSPOTList: [],
            SelectedPRINTList: [],
            CommissionBasis: null,
            NetMedia: "NET",
            PrintMedia: "PRINT",
            SpotMedia: "SPOT",
            CommissionPercentage: null,
            MediaTypes: [],
            dropzoneOptions: {
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/InterCompany/ICDraftUpload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert('Maximum File Upload limit is 5!');
                        }
                    });
                    this.on("removedfile", function () {
                    });
                },
            },
        };
    },
    computed: {
        // showprodbilling(){
        //  return this.ClientCommissionInfo!=null && this.ClientCommissionInfo.length>0;
        // },
        MediaList() {
            let list = [];
            list.push({
                label: "Net",
                value: NETMedia
            });
            list.push({
                label: "Spot",
                value: SPOTMedia
            });
            list.push({
                label: "Print",
                value: PRINTMedia
            });
            return list;
        },
        IsAllNetMediaNotSelected() {
            return this.NETList != null && this.NETList.length > 0;
        },
        IsAllSpotMediaNotSelected() {
            return this.SP.OTList != null && this.SPOTList.length > 0;
        },
        IsAllPrintMediaNotSelected() {
            return this.PRINTList != null && this.PRINTList.length > 0;
        },
        ...mapGetters("client", {
            ClientCommissionInfo: "clientCommissionInfo",
            PaymentTerms: "PaymentTerms",
            ClientProductList: "clientProductList",
            BillingDefaultPaymentTerms: "ClientBillingInformationsDefaultPaymentTerms",
            ClientBasicDetails: "ClientBasicDetails"
        }),

        ...mapGetters("client", {
            clientinfopagetwo: "ClientEularInfo",
        }),

        ...mapGetters("client", {
            clientinfopageone: "ClientBasicDetails",
        }),

        ...mapGetters("client", {
            clientinfopagethree: "ClientBillingInformations",
        }),

        SelectedMedia() {
            return (
                this.TimeOnly == "true" ||
                this.IsMediaUsed(this.SelectedNETList) |
                this.IsMediaUsed(this.SelectedPRINTList) ||
                this.IsMediaUsed(this.SelectedSPOTList)
            );
        },
        CommissionBasisList() {
            return this.CommissionBasisOptions.map((val) => {
                return {
                    label: val.text,
                    value: val.value
                };
            });
        },
        TimeOnlySelected() {
            let timeOnlyIndex = this.ClientCommissionInfo.findIndex((media) => {
                return media.MediaCategory == "TimeOnly";
            });
            return timeOnlyIndex != -1;
        },
        ProductSelectedMedia() {
            let mediaSelected = [];
            if (this.ClientProductList != null && this.ClientProductList.length > 0) {
                this.ClientProductList.forEach((val) => {
                    val.Media.forEach((media) => {
                        mediaSelected.push(media.MediaType);
                    });
                });
            }
            return mediaSelected;
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        }
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
        this.showprodbilling = this.ClientCommissionInfo.length > 0;
    },
    created() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
        this.InitiateObject();
    },
    watch: {
        MediaTypes(val, old) {
            if (old.includes('Bundled') && val.includes('Unbundled')) {
                var index5 = val.indexOf('Bundled');
                if (index5 > -1) {
                    val.splice(index5, 1);
                }
            } else
            if (old.includes('Unbundled') && val.includes('Bundled')) {
                var index6 = val.indexOf('Unbundled');
                if (index6 > -1) {
                    val.splice(index6, 1);
                }
            } else
            if (val.includes('Bundled') && val.includes('Unbundled')) {
                var index = val.indexOf('Unbundled');
                if (index > -1) {
                    val.splice(index, 1);
                }
                var index1 = val.indexOf('Bundled');
                if (index1 > -1) {
                    val.splice(index1, 1);
                }
            }

            if (val.includes('Cable') && val.includes('National') && val.includes('Syndication')) {
                return val;
            } else if (val.includes('Cable') && !val.includes('National') && !val.includes('Syndication')) {
                val.push('National');
                val.push('Syndication');
            } else if (val.includes('National') && !val.includes('Cable') && !val.includes('Syndication')) {
                val.push('Cable');
                val.push('Syndication');
            } else if (val.includes('Syndication') && !val.includes('National') && !val.includes('Cable')) {
                val.push('Cable');
                val.push('National');
            } else if (old.includes('Cable') && old.includes('National') && old.includes('Syndication')) {
                if (!val.includes('Cable') || !val.includes('National') || !val.includes('Syndication')) {
                    var cableindex = val.indexOf('Cable');
                    if (cableindex > -1) {
                        val.splice(cableindex, 1);
                    }

                    var nationalindex = val.indexOf('National');
                    if (nationalindex > -1) {
                        val.splice(nationalindex, 1);
                    }

                    var synindex = val.indexOf('Syndication');
                    if (synindex > -1) {
                        val.splice(synindex, 1);
                    }
                }
            }

        },
        "ClientCommissionInfo": {
            deep: true,
            handler() {
                this.showprodbilling = this.ClientCommissionInfo.length > 0;
                this.ShowScreenshot = this.DidRequiresNetworkCfoApproval();
                if(!this.ShowScreenshot){
                    this.ClientBasicDetails.IsNetworkCfoApproved = null;
                }
            }
        },
        "ClientBasicDetails.IsNetworkCfoApproved": function (val) {
            if(val!="true") {
                this.ClientBasicDetails.CreditVoyagerApprovalFiles = [];
            }
            if(val == "false") {
                this.ShowModelPopup = true;
            }
        },
        "ClientBasicDetails.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                this.ManuallyUploadFiles(this.ClientBasicDetails.CreditVoyagerApprovalFiles);
            }
        },
        FeeOnly(value) {
            this.ResetCommissionBasis(value);
        },
        CommissionBasis(value) {
            this.ResetFeeOnly(value);
        },
        TimeOnly(val) {
            if (val == "true") {
                this.ResetObject();
            }
        },
        prodbilling(val) {
            //window.console.log(val);
            this.ClientBasicDetails.ProductionBilling = val;
        }
    },
    methods: {
        tipMethod() {
            let tooltipvalue = '';
            if (this.ClientCommissionInfo.length) {
                tooltipvalue = 'This option cannot be selected if media is listed below. Please clear the table with the media selected to enable the checkbox for Production billable only.';
            }
            return tooltipvalue;
        },
        ResetDirty(prop) {
            this.$v[prop].$reset();
        },
        InitiateObject() {
            this.TimeOnly = null;
            this.ResetObject();
        },
        ResetObject() {
            this.CommissionBasis = null;
            this.FeeOnly = null;
            this.CommissionPercentage = null;
            this.ReloadNETMedia();
            this.ReloadSPOTMedia();
            this.ReloadPRINTMedia();
            this.SelectedNETList = [];
            this.SelectedSPOTList = [];
            this.SelectedPRINTList = [];
        },
        ReloadNETMedia() {
            window.console.log("reloading NET");
            this.NETList = [];
            NETMedia.forEach((val) => {
                this.NETList.push({
                    ...val
                });
            });
            // if (this.ClientCommissionInfo.length > 0) {
            //   this.prodbilling = false;
            //   this.showprodbilling = true;
            // } else {
            //   this.prodbilling = true;
            //   this.showprodbilling = false;
            // }
            window.console.log(this.NETList);
        },
        ReloadSPOTMedia() {
            window.console.log("reloading SPOT");
            this.SPOTList = [];
            SPOTMedia.forEach((val) => {
                this.SPOTList.push({
                    ...val
                });
            });
            // if (this.ClientCommissionInfo.length > 0) {
            //   this.prodbilling = false;
            //   this.showprodbilling = true;
            // } else {
            //   this.prodbilling = true;
            //   this.showprodbilling = false;
            // }
            window.console.log(this.SPOTList);
        },
        ReloadPRINTMedia() {
            window.console.log("reloading PRINT");
            this.PRINTList = [];
            PRINTMedia.forEach((val) => {
                this.PRINTList.push({
                    ...val
                });
            });
            // if (this.ClientCommissionInfo.length > 0) {
            //   this.prodbilling = false;
            //   this.showprodbilling = true;
            // } else {
            //   this.prodbilling = true;
            //   this.showprodbilling = false;
            // }
            window.console.log(JSON.stringify(this.PRINTList));
        },
        ResetCommissionBasis(value) {
            if (value) {
                this.CommissionBasis = null;
                this.CommissionPercentage = null;
            }
        },
        ResetFeeOnly(value) {
            if (value) {
                this.FeeOnly = null;
            }
        },
        GetSelectedMediaTypes() {
            let mediaList = [];
            if (
                this.ClientCommissionInfo != null &&
                this.ClientCommissionInfo.length > 0
            ) {
                this.ClientCommissionInfo.forEach((mediaType) => {
                    if (mediaType.MediaCategory != "TimeOnly") {
                        mediaType.Media.forEach((media) => {
                            mediaList.push(media.MediaType);
                        });
                    }
                });
            }
            return mediaList;
        },
        DeleteFromMediaList(media, selectedMediaType) {
            for (var i = media.length - 1; i >= 0; i--) {
                if (selectedMediaType.includes(media[i].MediaType)) {
                    this.$delete(media, i);
                }
            }
        },
        RemoveSelectedMedia() {
            let selectedMediaType = this.GetSelectedMediaTypes();
            this.DeleteFromMediaList(this.NETList, selectedMediaType);
            this.DeleteFromMediaList(this.PRINTList, selectedMediaType);
            this.DeleteFromMediaList(this.SPOTList, selectedMediaType);
        },
        DeleteAll() {
            if (this.ClientCommissionInfo.length > 0) {
                for (var i = 0; i < this.ClientCommissionInfo.length; i++) {
                    if (this.ClientCommissionInfo[i].MediaCategory.length > 0) {
                        for (var k = 0; k < this.ClientCommissionInfo[i].Media.length; k++) {
                            this.ClientCommissionInfo.splice(i, 2);
                        }
                    }
                }
                this.ShowScreenshot = false;
                this.ClientBasicDetails.IsNetworkCfoApproved = null;
            }
        },
        AddMedia() {
            this.RemoveSelectedMedia();
            this.$v.CommissionPercentage.$reset();
            this.showAddMedia = true;
            this.prodbilling = false;
            this.showprodbilling = true;
        },
        AddSelectedMedia() {
            store.commit("client/AddMediaToList", this.GetUsedMedia());
            this.showAddMedia = false;
            this.MediaTypes = [];
            this.InitiateObject();
        },
        CreateObject(data, timeOnly) {
            if (!timeOnly) {
                return {
                    ...data,
                    FeeOnly: this.FeeOnly || "No",
                    CommissionBasis: this.CommissionBasis,
                    CommissionPercentage: this.CommissionPercentage,
                    DefaultPaymentTerms: this.BillingDefaultPaymentTerms,
                    detailsShowing: true,
                };
            } else {
                return {
                    Media: [],
                    FeeOnly: "Yes",
                    CommissionBasis: null,
                    CommissionPercentage: null,
                    DefaultPaymentTerms: null,
                };
            }
        },
        CreateUserObject(data, timeOnly) {
            if (!timeOnly) {
                return {
                    ...data,
                    FeeOnly: this.FeeOnly || "No",
                    CommissionBasis: this.CommissionBasis,
                    CommissionPercentage: this.CommissionPercentage,
                    DefaultPaymentTerms: this.BillingDefaultPaymentTerms,
                    detailsShowing: true,
                };
            } else {
                return {
                    Media: [],
                    FeeOnly: "Yes",
                    CommissionBasis: null,
                    CommissionPercentage: null,
                    DefaultPaymentTerms: null,
                };
            }
        },
        IsMediaSelected(list) {
            let selectedMedia = -1;
            selectedMedia = list.findIndex((obj) => {
                return obj.Selected;
            });
            if (selectedMedia > -1) {
                return true;
            }
        },
        IsMediaUsed(list) {
            return list != null && list.length > 0;
        },
        IsMediaAlreadyAdded(name, mediaType) {
            if (this.ClientCommissionInfo && this.ClientCommissionInfo.length > 0) {
                let mediaTypeFound = this.ClientCommissionInfo.findIndex((val) => {
                    return val.MediaCategory == mediaType;
                });
                if (mediaTypeFound != -1) {
                    let itemFound = this.ClientCommissionInfo[
                        mediaTypeFound
                    ].Media.findIndex((val) => {
                        return val.MediaType == name;
                    });
                    return itemFound != -1;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        GetSelectedMedia() {
            let selectedMedia = [];
            if (selectedMedia.length < 1 && this.TimeOnly == "true") {
                selectedMedia.push({
                    MediaCategory: "TimeOnly",
                    Media: []
                });
                return selectedMedia;
            }
            let tempList = [];
            this.NETList.forEach((obj) => {
                if (
                    obj.Selected &&
                    !this.IsMediaAlreadyAdded(obj.MediaType, this.NetMedia)
                ) {
                    tempList.push(this.CreateObject(obj));
                }
            });
            if (tempList.length > 0) {
                selectedMedia.push({
                    MediaCategory: this.NetMedia,
                    Media: tempList,
                    _showDetails: true,
                });
            }
            tempList = [];
            this.PRINTList.forEach((obj) => {
                if (
                    obj.Selected &&
                    !this.IsMediaAlreadyAdded(obj.MediaType, this.PrintMedia)
                ) {
                    tempList.push(this.CreateObject(obj));
                }
            });
            if (tempList.length > 0) {
                selectedMedia.push({
                    MediaCategory: this.PrintMedia,
                    Media: tempList,
                    _showDetails: true,
                });
            }
            tempList = [];

            this.SPOTList.forEach((obj) => {
                if (
                    obj.Selected &&
                    !this.IsMediaAlreadyAdded(obj.MediaType, this.SpotMedia)
                ) {
                    tempList.push(this.CreateObject(obj));
                }
            });
            if (tempList.length > 0) {
                selectedMedia.push({
                    MediaCategory: this.SpotMedia,
                    Media: tempList,
                    _showDetails: true,
                });
            }
            return selectedMedia;
        },
        DidRequiresNetworkCfoApproval(){
            var result = false;
            if(this.ClientCommissionInfo.length > 0){
                this.ClientCommissionInfo.forEach((media)=> {
                    media.Media.forEach((payment) => {
                        if(payment.DefaultPaymentTerms && payment.DefaultPaymentTerms.length == 4){
                            let paymentTerm = Number(payment.DefaultPaymentTerms.substr(1))
                            if(paymentTerm != "NaN" && paymentTerm > 30) {
                                result = true;
                            }
                        }
                    });
                });
            }
            return result;
        },
        mountedmyVueDropzone() {
            if (this.IsEdit == true && this.FilesUploaded == false) {
                this.ManuallyUploadFiles(this.ClientBasicDetails.CreditVoyagerApprovalFiles);
                this.$set(this.ClientBasicDetails, "CreditVoyagerApprovalFiles", this.GetFilesObject());
            }
        },
        ScreenshotUploadedToDropzone() {
            this.$set(this.ClientBasicDetails, "CreditVoyagerApprovalFiles", this.GetFilesObject());
        },
        ScreenshotRemovedFromDropzone() {
            this.$set(this.ClientBasicDetails, "CreditVoyagerApprovalFiles", this.GetFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.screenshotVueDropzone.dropzone.files && this.$refs.screenshotVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.screenshotVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,file:filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        ManuallyUploadFiles(files) {
            if (this.$refs.screenshotVueDropzone != null) {
                if (files != null && files.length > 0 && this.FilesUploaded == false) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.screenshotVueDropzone.manuallyAddFile(file, url);
                    });
                }
                this.FilesUploaded = true;
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details">
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        closeModel(){
            this.ShowModelPopup = false;
        },
        FindMedia(mediaList, mediaType, mediaCategory) {
            let mediaFound = mediaList.findIndex((val) => {
                return val.value == mediaType;
            });
            if (mediaFound > -1) {
                return mediaCategory;
            } else {
                return "";
            }
        },

        GetMediaCategory(mediaType) {
            let MediaCategory = this.FindMedia(NETMedia, mediaType, this.NetMedia);
            if (MediaCategory == "")
                MediaCategory = this.FindMedia(SPOTMedia, mediaType, this.SpotMedia);
            if (MediaCategory == "")
                MediaCategory = this.FindMedia(PRINTMedia, mediaType, this.PrintMedia);
            return MediaCategory;
        },

        GetUsedMedia() {
            let selectedMedia = [];
            if (selectedMedia.length < 1 && this.TimeOnly == "true") {
                selectedMedia.push({
                    MediaCategory: "TimeOnly",
                    Media: []
                });
                return selectedMedia;
            }
            if (selectedMedia.length < 1 && this.prodbilling == "true") {
                selectedMedia.push({
                    MediaCategory: "productionbilling",
                    Media: [],
                    _showDetails: true
                });
                return selectedMedia;
            }
            let NETtempList = [];
            let SPOTtempList = [];
            let PRINTtempList = [];
            let completetempList = [];

            if (this.MediaTypes != null && this.MediaTypes.length > 0) {
                this.MediaTypes.forEach((media) => {
                    completetempList.push({
                        MediaCategory: this.GetMediaCategory(media),
                        MediaType: media,
                    });
                });
            }

            if (completetempList != null && completetempList.length > 0) {
                completetempList.forEach((obj) => {
                    if (obj.MediaCategory == "NET") {
                        if (!this.IsMediaAlreadyAdded(obj, this.NetMedia)) {
                            NETtempList.push(
                                this.CreateUserObject({
                                    MediaType: obj.MediaType
                                })
                            );
                        }
                    }
                    if (obj.MediaCategory == "PRINT") {
                        if (!this.IsMediaAlreadyAdded(obj, this.PrintMedia)) {
                            PRINTtempList.push(
                                this.CreateUserObject({
                                    MediaType: obj.MediaType
                                })
                            );
                        }
                    }
                    if (obj.MediaCategory == "SPOT") {
                        if (!this.IsMediaAlreadyAdded(obj, this.SpotMedia)) {
                            SPOTtempList.push(
                                this.CreateUserObject({
                                    MediaType: obj.MediaType
                                })
                            );
                        }
                    }
                });

                if (NETtempList.length > 0) {
                    selectedMedia.push({
                        MediaCategory: this.NetMedia,
                        Media: NETtempList,
                        _showDetails: true,
                    });
                }

                if (PRINTtempList.length > 0) {
                    selectedMedia.push({
                        MediaCategory: this.PrintMedia,
                        Media: PRINTtempList,
                        _showDetails: true,
                    });
                }

                if (SPOTtempList.length > 0) {
                    selectedMedia.push({
                        MediaCategory: this.SpotMedia,
                        Media: SPOTtempList,
                        _showDetails: true,
                    });
                }
            }
            return selectedMedia;
        },

        CancelAdd() {
            this.showAddMedia = false;
            this.showprodbilling = this.ClientCommissionInfo.length > 0;
            this.MediaTypes = [];
            this.InitiateObject();
        },
        IsMediaIsUsedInProduct(name) {
            return this.ProductSelectedMedia.includes(name);
        },
        DeleteMedia(parentIndex, index, name) {
            if (this.ClientCommissionInfo[parentIndex].Media.length <= 1) {
                // store.commit('client/RemoveMediaType',parentIndex);
                this.ClientCommissionInfo.splice(parentIndex, 1);
            } else {
                if (this.IsMediaIsUsedInProduct(name)) {
                    store.dispatch(
                        "progress/setErrorMessage",
                        `Can't delete media ${name} as it is used in product. Please delete the item from the product to proceed.`
                    );
                } else {
                    let itemIndex = this.ClientCommissionInfo[
                        parentIndex
                    ].Media.findIndex((val) => {
                        return val.MediaType == name;
                    });
                    this.ClientCommissionInfo[parentIndex].Media.splice(itemIndex, 1);
                }
            }
            this.showprodbilling = this.ClientCommissionInfo.length > 0;
        },
        NavigateToRoute(name) {
            if (this.id) {
                this.$router.push({
                    name: name,
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            this.NavigateToRoute("Product Info");
        },

        SaveasDraft() {
            this.$store
                .dispatch("client/SaveClientDraft", this.isDraft == true ? parseInt(this.id) : null)
                .then((res) => {
                    window.console.log(JSON.stringify(res));
                    if (res.status == 200) {
                        store.commit("progress/removeProcessing", {
                            root: true
                        });
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `The Client request have been saved as Draft successfully`
                        );

                        this.$router.push("/ClientProduct");
                    } else {
                        window.console.log('error while uploading files');
                    }

                })
                .catch((ex) => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Saving Client as Draft. ${ex}`
                    );
                });
        },

        Previous() {
            this.NavigateToRoute("Billing");
        },
        Cancel() {
            var guid = localStorage.getItem("UploadfileId");
            if (guid != "") {
                service
                    .removeDiretory(guid)
                    .then(() => {
                        this.$router.push("/ClientProduct");
                        localStorage.setItem("UploadfileId", "");
                    })
                    .catch((err) => {
                        alert(err);
                        this.$router.push("/ClientProduct");
                    });
            } else {
                    this.$router.push("/ClientProduct");
            }
        },
    },
};
</script>

<style scoped>
.productionbillinglbl {
    margin-top: 16px;
    margin-left: -16px;
    font-family: "Open Sans";
    font-size: 11px;
}

.inline-custom-radio-commission {
    display: inline !important;
    margin: 0 0.25rem !important;
}

.commission-basis-with-fee .card {
    padding-left: 0px !important;
}

.expand-grid:hover,
.collapse-grid:hover,
.delete-icon:hover {
    color: #018db8 !important;
    cursor: pointer !important;
}

.custom-inline-text {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4d5161 !important;
    padding-left: 10px;
}

.custom-text {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #4d5161 !important;
    padding-left: 10px;
}

.commission-basis {
    font-family: "Open Sans";
    font-size: 10px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

div.custom-radio-switch>label.btn-outline-primary.active {
    background-color: #00a1d2 !important;
}

.child-component>div:first-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.row.vertical-line:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 3rem;
    bottom: 22vh;
    left: 50%;
    border-left: 1px solid rgba(151, 151, 151, 0.19);
    transform: translate(-50%);
    height: 3.5rem;
}

.row.vertical-line>div {
    padding: 0px !important;
}

.row.vertical-line>div:first-child>fieldset {
    margin-bottom: -0.75rem !important;
}

.panel-top label {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #2c3865;
}

table.table-editable.b-table>thead>tr>th:first-child {
    padding-left: 30px !important;
    width: 23% !important;
}

.panel-top>label {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    color: #77a2bb;
    padding-left: 0px !important;
}

.panel-top {
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
}

/* .panel-content label.media-select {
  background-color: #eaebf0;
  border-radius: 17.5px;
  font-family: 'Open Sans';
  font-size: 12px;
  padding: 10px 20px;
}
.panel-content label.media-select{
 margin-right:1rem;
}

.panel-content label.media-select.selected-media {
  color:#fff;
  font-family:'Open Sans'-Semibold;
  background-color:#00a1d2;
} */

.secondary-card .vertical-line label.custom-control-label {
    font-family: "Open Sans" !important;
    font-weight: 600 !important;
}

.secondary-card>.card-body {
    padding: 0.75rem !important;
}

.card.secondary-card .card-body {
    background-color: white !important;
}

.ml-3,
.mx-3 {
    margin-left: 2rem !important;
}

.time-only {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4d5161;
    text-transform: capitalize;
}

.modal-dialog {
    max-width: calc(100% - 58.333%) !important;
    margin: 1.75rem auto;
}

.select-default {
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}
.modal-title {
    font-family: "Open Sans" !important;
}

.modelmessage {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

/* file upload */

.dz-max-files-reached {
    background-color: red;
}

.customdropzone>.dz-preview dz-file-preview {
    border-top: dashed 2px #e9eef0 !important;
}

.dz-image img {
    display: none !important;
}

.customdropzone {
    width: 350px !important;
    height: 44px !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.customCommissionInfoDropzone {
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

.filepond--drop-label.filepond--drop-label label {
    text-transform: inherit !important;
    color: darkgray !important;
    cursor: default !important;
    font-size: 0.875em !important;
    font-weight: 400 !important;
    text-align: center !important;
    line-height: 1.5 !important;
}
/* end of file upload */
</style>
